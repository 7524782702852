import { SubscriptionPlanType } from "../definitions/enums";
import { ISubscriptionPlan } from "../definitions/interfaces";

export const primaryColor = '#3194f1';

export const profileColors = {
  '81cacf': '#81cacf',
  '044f67': '#044f67',
  '7868e6': '#7868e6',
  'f4bfbf': '#f4bfbf',
  '38ada9': '#38ada9',
  '8b76a5': '#8b76a5',
  '6c567b': '#6c567b',
  'cd5d7d': '#cd5d7d',
  'f47983': '#f47983',
  'e5707e': '#e5707e',
  'ea907a': '#ea907a',
  'f6ae99': '#f6ae99',
  'fbc687': '#fbc687',
  'e8e9a1': '#e8e9a1',
  '5a8f7b': '#5a8f7b',
  'b8e994': '#b8e994',
  '76ba99': '#76ba99',
  '182c61': '#182c61',
  '6ecf72': '#6ecf72',
  '6a83cd': '#6a83cd',
  '33994d': '#33994d',
  '2a4a7e': '#2a4a7e',
  '3c256f': '#3c256f',
  '2f838e': '#2f838e',
  '69349d': '#69349d',
  '993392': '#993392',
  'c453c6': '#c453c6',
  '5b93c8': '#5b93c8'
};



export const defaultGridOptions = {
  
  defaultColDef: {
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    sidebar: true,
  },
  rowHoverHighlight: true,
  // suppressMenuHide: true,
  debug: true,
  groupDisplayType: 'groupRows',
  overlayNoRowsTemplate: 'No data found'

}


// Small -      $29 per month / billed annually
//              $33 per month / billed monthly
//              Up to 20 members

// Medium -     $49 per month / billed annually
//              $54 per month / billed monthly
//              Up to 30 members

// Large -      $120 per month / billed annually
//              $130 per month / billed monthly
//              Unlimited members (not sure if this should be unlimited or up to 100?)


export const LiveSubscriptionPlans: ISubscriptionPlan[] = [
  {
    name: 'Small',
    features: [
      'Up to 10 members',
    ],
    priceId: 'price_1NTL9iC13CZ7WmqGtqMRdFKw',
    price: 34,
    type: SubscriptionPlanType.small,
    billingPeriod: 'monthly'
  },
  {
    name: 'Small',
    features: [
      'Up to 10 members',
    ],
    priceId: 'price_1NTL9iC13CZ7WmqGjhTf3xEy',
    price: 348,
    type: SubscriptionPlanType.small,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium',
    features: [
      'Up to 20 members',
    ],
    priceId: 'price_1NTLCeC13CZ7WmqGi2Ip2y7n',
    price: 39,
    type: SubscriptionPlanType.medium,
    billingPeriod: 'monthly'
  },
  {
    name: 'Medium',
    features: [
      'Up to 20 members',
    ],
    priceId: 'price_1NTLCeC13CZ7WmqGMTEMBCfo',
    price: 408,
    type: SubscriptionPlanType.medium,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium Large',
    features: [
      'Up to 30 members',
    ],
    priceId: 'price_1PUf5XC13CZ7WmqG2C7CUYGd',
    price: 588,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium Large',
    features: [
      'Up to 30 members',
    ],
    priceId: 'price_1OQ4MfC13CZ7WmqGBRHBOhB5',
    price: 54,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: 'monthly'
  },
  {
    name: 'Large',
    features: [
      'Up to 50 members'
    ],
    priceId: 'price_1NTLFXC13CZ7WmqGinBc0Hp2',
    price: 69,
    type: SubscriptionPlanType.large,
    billingPeriod: 'monthly'
  },
  {
    name: 'Large',
    features: [
      'Up to 50 members'
    ],
    priceId: 'price_1NTLFXC13CZ7WmqGioqjT00o',
    price: 768,
    type: SubscriptionPlanType.large,
    billingPeriod: 'annually'
  }
];


export const TestSubscriptionPlans: ISubscriptionPlan[] = [
  {
    name: 'Small',
    features: [
      'Up to 20 members',
    ],
    priceId: 'price_1NNDTwC13CZ7WmqGNbi6bhI2',
    price: 34,
    type: SubscriptionPlanType.small,
    billingPeriod: 'monthly'
  },
  {
    name: 'Small',
    features: [
      'Up to 20 members',
    ],
    priceId: 'price_1NNDUxC13CZ7WmqGR9yg4bRP',
    price: 348,
    type: SubscriptionPlanType.small,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium',
    features: [
      'Up to 30 members',
    ],
    priceId: 'price_1NNDWWC13CZ7WmqGGlRpEZt0',
    price: 39,
    type: SubscriptionPlanType.medium,
    billingPeriod: 'monthly'
  },
  {
    name: 'Medium',
    features: [
      'Up to 30 members',
    ],
    priceId: 'price_1NNDWwC13CZ7WmqGabaS71qs',
    price: 408,
    type: SubscriptionPlanType.medium,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium Large',
    features: [
      'Up to 50 members',
    ],
    priceId: 'price_1OQ4PiC13CZ7WmqGkDmuHIxj',
    price: 588,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: 'annually'
  },
  {
    name: 'Medium Large',
    features: [
      'Up to 50 members',
    ],
    priceId: 'price_1OQ4PVC13CZ7WmqG9TyL9tBg',
    price: 54,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: 'monthly'
  },
  {
    name: 'Large',
    features: [
      'Unlimited members',
    ],
    priceId: 'price_1NNDXxC13CZ7WmqGaQHCnT8F',
    price: 69,
    type: SubscriptionPlanType.large,
    billingPeriod: 'monthly'
  },
  {
    name: 'Large',
    features: [
      'Unlimited members',
    ],
    priceId: 'price_1NNDYSC13CZ7WmqG92gBr40W',
    price: 768,
    type: SubscriptionPlanType.large,
    billingPeriod: 'annually'
  }
];

export const SubscriptionPlans = process.env.NODE_ENV === 'production' ? LiveSubscriptionPlans : TestSubscriptionPlans;

export const IntroductionSteps = [
  {
    element: '#dashboard-welcome',
    intro: 'Welcome to OneFile!',
    position: 'bottom'
  },
  {
    element: '#dashboard-welcome',
    intro: 'This is your dashboard. Here you can see your upcoming events, your upcoming tasks, and your upcoming appointments.',
    position: 'bottom'
  },
  {
    element: '#dashboard-welcome',
    intro: 'You can also see your upcoming events, your upcoming tasks, and your upcoming appointments.',
    position: 'bottom'
  }
];

export const RosterIntroductionSteps = [
  {
    element: '#roster-welcome',
    intro: 'Welcome to the one file roster!',
    position: 'bottom'
  },
  {
    element: '#staff-members',
    intro: 'This is your roster. Here you can see all of your staff members under all of your workspaces.',
    position: 'bottom'
  },
  {
    element: '#staff-members',
    intro: 'You can assign staff members to workspaces from the all staff members page.',
    position: 'bottom'
  },
  {
    element: '#roster-container',
    intro: 'This is your roster. Here you can click and drag on a row to create a time.',
    position: 'bottom'
  },
  {
    element: '#roster-container',
    intro: 'Timeslots can be dragged to change their start and end time.  These changes are inremented by 30 minutes.  You can click on a timeslot to edit to any minute value or remove the timeslot.',
    position: 'bottom'
  },
  {
    element: '#save-button',
    intro: 'Changes can be saved by clicking the save button.',
    position: 'right'
  },
  {
    element: '#email-button',
    intro: 'You can email your roster to your staff members by clicking the email button.',
    position: 'top',
  },
  {
    element: '#add-staff-button',
    intro: 'You can add a new staff member manually by clicking the add staff button.',
    position: 'top',
  },
  {
    element: '#date-range-picker',
    intro: 'You can select a date range to view your roster.',
    position: 'top',
  }
]


export const DashboardIntroductionSteps = [
  {
    element: '#staff-welcome',
    intro: 'Welcome to your OneFile dashboard.  This page gives you an overview of your organisation.',
    position: 'bottom'
  },
  {
    element: '#workspaces',
    intro: 'A workspace is a group of staff members.  It could be a room, a branch, or department of your business.  You can add a new workspace by clicking the add workspace button.',
  },
  {
    element: '#staff-members',
    intro: 'This is your staff members page. Here you can see all of your staff members under all of your workspaces.',
    position: 'bottom'
  },
  {
    element: '#rosters',
    intro: 'You can create a roster for your staff members by clicking the create roster button.',
    position: 'bottom',
  },
  {
    element: '#staff-checkin',
    intro: 'You can use a sign in process to check in your staff members by going to Staff on site.  This will allow you to monitor hours and attendance.',
  },

];

export const TUTS = {
  DASHBOARD: DashboardIntroductionSteps
}


export const fileIcons = [
  { name: 'address-book', icon: 'address-book.svg' },
  { name: 'airplane', icon: 'airplane.svg' },
  { name: 'ambulance', icon: 'ambulance.svg' },
  { name: 'automobile', icon: 'automobile.svg' },
  { name: 'available', icon: 'available.png' },
  { name: 'bank', icon: 'bank.svg' },
  { name: 'beach', icon: 'beach.svg' },
  { name: 'bell', icon: 'bell.png' },
  { name: 'birthday-cake', icon: 'birthday-cake.png' },
  { name: 'book1', icon: 'book1.svg' },
  { name: 'bookmark', icon: 'bookmark.svg' },
  { name: 'briefcase', icon: 'briefcase.svg' },
  { name: 'british-breakfast', icon: 'british-breakfast.svg' },
  { name: 'bubble-comment', icon: 'bubble-comment.svg' },
  { name: 'builder', icon: 'builder.svg' },
  { name: 'bulb', icon: 'bulb.svg' },
  { name: 'bullhorn', icon: 'bullhorn.svg' },
  { name: 'bullseye', icon: 'bullseye.svg' },
  { name: 'bust', icon: 'bust.svg' },
  { name: 'busts', icon: 'busts.svg' },
  { name: 'cake', icon: 'cake.svg' },
  { name: 'cake2', icon: 'cake2.svg' },
  { name: 'calendar-date', icon: 'calendar-date.svg' },
  { name: 'calendar-reminder', icon: 'calendar-reminder.svg' },
  { name: 'card-file', icon: 'card-file.svg' },
  { name: 'card-index', icon: 'card-index.svg' },
  { name: 'chart-increase', icon: 'chart-increase.svg' },
  { name: 'chart-increasing', icon: 'chart-increasing.svg' },
  { name: 'chart', icon: 'chart.svg' },
  { name: 'checkout-list', icon: 'checkout-list.svg' },
  { name: 'classical-building', icon: 'classical-building.svg' },
  { name: 'clock-time', icon: 'clock-time.svg' },
  { name: 'clock', icon: 'clock.png' },
  { name: 'cog', icon: 'cog.svg' },
  { name: 'confetti', icon: 'confetti.png' },
  { name: 'construction', icon: 'construction.svg' },
  { name: 'credit-card', icon: 'credit-card.svg' },
  { name: 'diagram', icon: 'diagram.svg' },
  { name: 'disk', icon: 'disk.svg' },
  { name: 'doctor', icon: 'doctor.svg' },
  { name: 'file-storage', icon: 'file-storage.png' },
  { name: 'file', icon: 'file.svg' },
  { name: 'fixing-repair', icon: 'fixing-repair.svg' },
  { name: 'flag', icon: 'flag.svg' },
  { name: 'fuel', icon: 'fuel.svg' },
  { name: 'gift-memory', icon: 'gift-memory.svg' },
  { name: 'green-book', icon: 'green-book.svg' },
  { name: 'hammer', icon: 'hammer.svg' },
  { name: 'hat-birthday', icon: 'hat-birthday.svg' },
  { name: 'healthcare-hospital', icon: 'healthcare-hospital.svg' },
  { name: 'healthcare', icon: 'healthcare.svg' },
  { name: 'hourglass-time', icon: 'hourglass-time.svg' },
  { name: 'house-garden', icon: 'house-garden.svg' },
  { name: 'house', icon: 'house.svg' },
  { name: 'houses', icon: 'houses.svg' },
  { name: 'inbox', icon: 'inbox.svg' },
  { name: 'judge', icon: 'judge.svg' },
  { name: 'key', icon: 'key.svg' },
  { name: 'keyboard', icon: 'keyboard.svg' },
  { name: 'laptop', icon: 'laptop.svg' },
  { name: 'layers', icon: 'layers.svg' },
  { name: 'ledger', icon: 'ledger.svg' },
  { name: 'locked-pen', icon: 'locked-pen.svg' },
  { name: 'locked', icon: 'locked.svg' },
  { name: 'magnifying-glass', icon: 'magnifying-glass.svg' },
  { name: 'man-in-manual-wheelchair', icon: 'man-in-manual-wheelchair.svg' },
  { name: 'map', icon: 'map.svg' },
  { name: 'medical', icon: 'medical.svg' },
  { name: 'meeting', icon: 'meeting.png' },
  { name: 'memo', icon: 'memo.svg' },
  { name: 'menu', icon: 'menu.svg' },
  { name: 'money', icon: 'money.svg' },
  { name: 'mortarboard', icon: 'mortarboard.svg' },
  { name: 'office-building', icon: 'office-building.svg' },
  { name: 'outbox', icon: 'outbox.svg' },
  { name: 'package', icon: 'package.svg' },
  { name: 'page-facing-up', icon: 'page-facing-up.svg' },
  { name: 'page-with-curl', icon: 'page-with-curl.svg' },
  { name: 'paperclip', icon: 'paperclip.svg' },
  { name: 'party-hat', icon: 'party-hat.png' },
  { name: 'party-popper', icon: 'party-popper.svg' },
  { name: 'pen', icon: 'pen.svg' },
  { name: 'person-protection', icon: 'person-protection.svg' },
  { name: 'phone-receiver', icon: 'phone-receiver.svg' },
  { name: 'pill', icon: 'pill.svg' },
  { name: 'placard', icon: 'placard.svg' },
  { name: 'police-cap', icon: 'police-cap.svg' },
  { name: 'police-shield', icon: 'police-shield.svg' },
  { name: 'post-office', icon: 'post-office.svg' },
  { name: 'receipt', icon: 'receipt.svg' },
  { name: 'roster', icon: 'roster.png' },
  { name: 'round-pushpin', icon: 'round-pushpin.svg' },
  { name: 'schedule', icon: 'schedule.png' },
  { name: 'shield', icon: 'shield.svg' },
  { name: 'shirt', icon: 'shirt.svg' },
  { name: 'spiral-calendar-', icon: 'spiral-calendar-.svg' },
  { name: 'staff-on-site', icon: 'staff-on-site.png' },
  { name: 'star-prize', icon: 'star-prize.svg' },
  { name: 'store-verified', icon: 'store-verified.svg' },
  { name: 'student', icon: 'student.svg' },
  { name: 'telephone', icon: 'telephone.svg' },
  { name: 'testimonial', icon: 'testimonial.svg' },
  { name: 'thermometer', icon: 'thermometer.svg' },
  { name: 'ticket', icon: 'ticket.svg' },
  { name: 'ticket2', icon: 'ticket2.svg' },
  { name: 'traffic-lights', icon: 'traffic-lights.svg' },
  { name: 'triangle-measure', icon: 'triangle-measure.svg' },
  { name: 'unlocked', icon: 'unlocked.svg' },
  { name: 'warning', icon: 'warning.svg' },
  { name: 'woman-police-officer', icon: 'woman-police-officer.svg' },
  { name: 'world', icon: 'world.svg' },
  { name: 'world2', icon: 'world2.svg' },
  { name: 'writing', icon: 'writing.svg', }
]

export interface IHomePageContent {
  banner: {
    image?: string;
    heading: string;
    blurb: string;
  }
  features: {
    heading: string;
    blurb: string;
    items: string[];
  }[];
  contact: {
    heading?: string;
    blurb?: string;
    contactEmail?: string;
    contactPhone?: string
  };
  testimonials: {
    heading: string;
    blurb: string;
    customer: string;
  };
  whatWeDo: {
    heading: string;
    blurb: string;
  };
  whyChooseUs: {
    heading: string;
    blurb: string;
  };
  pricingPlans: {
    heading: string;
    blurb: string;
  }


}

export const HomePageContent: IHomePageContent = {

}

export const rosterDefaultProperties = {
  businessHours: {
    start: new Date('2022-07-21T18:00:00.159Z'),
    end: new Date("2022-07-22T06:30:00.381Z")
  },
  isEditableByManagers: false,
  nestStaffMembersInRooms: true,
  showAllspaces: true,
  showCalendarView: false,
  showDayView: false,
  showStaffMemberAvatars: true,
  showStaffMemberNames: true,
  showStaffMembersPosition: false,
  showTimeslotRange: true,
  showWeekView: false,
  useProfileColors: true,
  showOnlyBusinessHours: true,
}

export const DEFAULT_DASHBOARD_SETTINGS = {
    showCalendar: true,
    showExpiringFiles: true,
    showMyAvailability: true,
    showMyLeaveDates: true,
    showMySchedule: true,
    showNoticeBoard: true,
    showPendingLeaveRequests: true,
    showRecentMeetings: true,
    showStaffOnLeave: true,
    showStaffOnSite: true,
    showTimeTrackingTile: true,
    showOrganizationLogo: true,
    showTodaysRoster: true,
    showUpcomingBirthdays: true
};

export const DEFAULT_GENERAL_FEATURE_SETTINGS = {
  feature_meeting:true,
  feature_leave_request:true,
  feature_sign_in_on_site:true,
  feature_performance_review:true,
  feature_roster:true,
  feature_time_tracker:true
}

export const PermissionModules = [
{
  name: 'Meetings',
  description: '',
  permissions: [{
      permission: 'meeting_can_create',
      label: 'Create'
    },
    {
      permission: 'meeting_can_view',
      label: 'View'
    },
    {
      permission: 'meeting_can_delete',
      label: 'Delete'
    },
    {
      permission: 'meeting_can_edit',
      label: 'Edit'
    },
    {
      permission: 'meeting_can_complete',
      label: 'Complete'
    },
  ],
}, {
  name: 'Time Tracker',
  permissions: [
    {
      permission: 'time_tracker_can_view',
      label: 'View'
    }
    ],
    onlyRoles: ['admin', 'manager']
}, {
  name: 'Roster',
  permissions: [{
      permission: 'roster_can_create',
      label: 'Create'
    },
    {
      permission: 'roster_can_view',
      label: 'View'
    },
    {
      permission: 'roster_can_edit',
      label: 'Edit'
    },
    {
      permission: 'roster_can_delete',
      label: 'Delete'
    },
  ],
    
}, {
  name: `On site sign in's`,
  permissions: [{
      permission: 'check_in_can_create',
      label: 'Create'
    },
    {
      permission: 'check_in_can_view',
      label: 'View'
    },
    {
      permission: 'check_in_can_edit',
      label: 'Edit'
    },
    {
      permission: 'check_in_can_delete',
      label: 'Delete'
    },
    
  ],
  },
  // {
  // name: 'Library',
  // permissions: [{
  //     permission: 'library_can_create',
  //     label: 'Create'
  //   },
  //   {
  //     permission: 'library_can_view',
  //     label: 'View'
  //   },
  //   {
  //     permission: 'library_can_edit',
  //     label: 'Edit'
  //   },
  //   {
  //     permission: 'library_can_upload',
  //     label: 'Upload files'
  //   },
  //   {
  //     permission: 'library_can_delete',
  //     label: 'Delete files'
  //   },
  // ],
  // },
  // {
  // name: 'Dashboard',
  // permissions: [{
  //     permission: 'dashboard_can_view',
  //     label: 'View Dashboard'
  //   },
  //   {
  //     permission: 'dashboard_show_calendar',
  //     label: 'Show calendar'
  //   },
  //   {
  //     permission: 'dashboard_show_expiring_files',
  //     label: 'Show expiring files'
  //   },
  //   {
  //     permission: 'dashboard_show_my_availability',
  //     label: 'Show my availability'
  //   },
  //   {
  //     permission: 'dashboard_show_my_leave_dates',
  //     label: 'Show my leave dates'
  //   },
  //   {
  //     permission: 'dashboard_show_my_schedule',
  //     label: 'Show my schedule'
  //   },
  //   {
  //     permission: 'dashboard_show_notice_board',
  //     label: 'Show notice board'
  //   },
  //   {
  //     permission: 'dashboard_show_organization_logo',
  //     label: 'Show organization logo'
  //   },
  //   {
  //     permission: 'dashboard_show_pending_leave_requests',
  //     label: 'Show pending leave requests'
  //   },
  //   {
  //     permission: 'dashboard_show_recent_meetings',
  //     label: 'Show recent meetings'
  //   },
  //   {
  //     permission: 'dashboard_show_staff_on_leave',
  //     label: 'Show staff on leave'
  //   },
  //   {
  //     permission: 'dashboard_show_staff_on_site',
  //     label: 'Show staff on site'
  //   },
  //   {
  //     permission: 'dashboard_show_time_tracking_tile',
  //     label: 'Show time tracking tile'
  //   },
  //   {
  //     permission: 'dashboard_show_todays_Roster',
  //     label: 'Show todays roster'
  //   },
  //   {
  //     permission: 'dashboard_show_upcoming_birthdays',
  //     label: 'Show upcoming birthdays'
  //   },
  // ],
  // },
  {
  name: 'Leave Requests',
  permissions: [{
      permission: 'leave_requests_can_create',
      label: 'Create'
    },
    {
      permission: 'leave_requests_can_view',
      label: 'View'
    },
    {
      permission: 'leave_requests_can_delete',
      label: 'Delete'
    },
    {
      permission: 'leave_requests_can_edit',
      label: 'Edit'
    },
    {
      permission: 'leave_requests_can_approve',
      label: 'Approve'
    },
    ],
  onlyRoles: ['admin', 'manager']
}, {
  name: 'Performance Reviews',
  permissions: [{
      permission: 'performance_reviews_can_create',
      label: 'Create'
    },
    {
      permission: 'performance_reviews_can_view',
      label: 'View'
    },
    {
      permission: 'performance_reviews_can_delete',
      label: 'Delete'
    },
    {
      permission: 'performance_reviews_can_edit',
      label: 'Edit'
    },
    {
      permission: 'performance_reviews_can_complete',
      label: 'Complete'
    },
    ],
    onlyRoles: ['admin', 'manager']
},
//  {
//   name: 'Tasks',
//   permissions: [{
//       permission: 'tasks_can_create',
//       label: 'Create'
//     },
//     {
//       permission: 'tasks_can_view',
//       label: 'View'
//     },
//     {
//       permission: 'tasks_can_delete',
//       label: 'Delete'
//     },
//     {
//       permission: 'tasks_can_edit',
//       label: 'Edit'
//     },
//     {
//       permission: 'tasks_can_complete',
//       label: 'Complete'
//     },
//   ],
// },
 ];
