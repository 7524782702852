export enum UserRole {
  staff_member,
  reviewer,
  manager,
  admin,
}

export enum Role {
  staff_member,
  manager,
  admin,
}

export enum MeetingStatus {
  closed,
  open,
}

export enum FormMode {
  view,
  create,
}

export enum TemplateStatus {
  unpublished,
  published,
}

export enum FieldTypes {
  CheckBoxes = "CheckBoxes",
  TextField = "TextField",
  Button = "Button",
  Select = "Select",
  MultipleChoice = "MultipleChoice",
  DatePicker = "DatePicker",
  TimePicker = "TimePicker",
  DateTimePicker = "DateTimePicker",
  Label = "Label",
  Workflow = "Workflow",
  Content = "Content",
}

export enum PubSubEvent {
  Notification = "Notification",
  GetSettings = "GetSettings",
  GetLastReadTimes = "GetLastReadTimes",
  ScreenSizeChanged = "ScreenSizeChanged",
  SettingsLoaded = "SettingsLoaded",
}

export enum NotificationType {
  info = "info",
  error = "error",
  warning = "warning",
  success = "success",
}

export enum RosterEditIntent {
  create = "create",
  edit = "edit",
  none = "none",
}

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export enum SubscriptionPlanType {
  starter,
  standard,
  premium,
  small,
  medium,
  large,
  mediumLarge,
}

export enum InventoryStatus {
  returned,
  lent,
}

export enum CheckinStatus {
  out,
  in,
}

export enum LastReadTimeContext {
  roster,
  communication,
  leave_request,
  training_course,
  meeting,
}

export enum CheckinType {
  work,
  sickLeave,
  annualLeave,
  bereavementLeave,
  maternityLeave,
  paternityLeave,
  unpaidLeave,
}

export enum NoticeType {
  urgent,
  warning,
  important,
  info,
}

export enum LeaveRequestStatus {
  pending,
  approved,
  rejected,
}

export enum TrainingCourseQuestionType {
  MultipleChoice,
  Text,
  TrueOrFalse,
}

export enum PerformanceReviewStatus {
  open,
  pending_review,
  pending_staff_input,
  complete,
}

export enum PerformanceReviewStatusString {
  open ='open',
  pending_review = 'pending_review',
  pending_staff_input = 'pending_staff_input',
  complete = 'complete',

}

export type PerformanceReviewStatusValue =
  | "open"
  | "pending_review"
  | "pending_staff_input"
  | "complete"


export enum PerformanceReviewRating {
  unrated,
  unacceptable,
  needs_improvement,
  meets_expectations,
  exceeds_expectations,
  outstanding,
}

export enum FeatureList {
  roster = "feature_roster",
  communication = "feature_communication",
  leave_request = "feature_leave_request",
  training_course = "feature_training_course",
  meeting = "feature_meeting",
  performance_review = "feature_performance_review",
  sign_in_on_site = "feature_sign_in_on_site",
  time_tracker = "feature_time_tracker",
  // tasks = "feature_tasks",
  health_and_safety = "feature_health_and_safety",
}

export enum TaskPriority {
  high,
  medium,
  low,
}

export enum TaskStatus {
  open,
  closed,
  pending,
  in_progress,
  in_review,
}

export enum HealthAndSafetyReportStatus {
  open,
  closed,
  investigating,
  complete,
}

export enum LibraryExplorerRoute {
  root = "root",
  staff_members = "staff-members",
  meetings = "meetings",
  health_and_safety = "health-and-safety-reports",
  other = "other",
  folder = "folder",
  search = "search",
}

export enum SupportIssueType {
  report_issue,
  request_feature,
  bug,
  task,
  story,
}

export enum SupportIssueStatus {
  open,
  closed,
  in_progress,
  reviewing,
  testing,
}

export enum SupportIssuePriority {
  high,
  medium,
  low,
}

export enum PlanType {
  small,
  medium,
  large,
  mediumLarge,
}

export enum PermissionType {
  meeting_can_create = "meeting_can_create",
  meeting_can_view = "meeting_can_view",
  meeting_can_delete = "meeting_can_delete",
  meeting_can_edit = "meeting_can_edit",
  meeting_can_complete = "meeting_can_complete",
  time_tracker_can_create = "time_tracker_can_create",
  time_tracker_can_view = "time_tracker_can_view",
  time_tracker_can_delete = "time_tracker_can_delete",
  time_tracker_can_edit = "time_tracker_can_edit",
  roster_can_create = "roster_can_create",
  roster_can_view = "roster_can_view",
  roster_can_delete = "roster_can_delete",
  roster_can_edit = "roster_can_edit",
  check_in_can_create = "check_in_can_create",
  check_in_can_view = "check_in_can_view",
  check_in_can_delete = "check_in_can_delete",
  check_in_can_edit = "check_in_can_edit",
  library_can_create = "library_can_create",
  library_can_view = "library_can_view",
  library_can_delete = "library_can_delete",
  library_can_edit = "library_can_edit",
  library_can_upload = "library_can_upload",
  dashboard_can_view = "dashboard_can_view",
  dashboard_show_calendar = "dashboard_show_calendar",
  dashboard_show_expiring_files = "dashboard_show_expiring_files",
  dashboard_show_my_availability = "dashboard_show_my_availability",
  dashboard_show_my_leave_dates = "dashboard_show_my_leave_dates",
  dashboard_show_my_schedule = "dashboard_show_my_schedule",
  dashboard_show_notice_board = "dashboard_show_notice_board",
  dashboard_show_organization_logo = "dashboard_show_organization_logo",
  dashboard_show_pending_leave_requests = "dashboard_show_pending_leave_requests",
  dashboard_show_recent_meetings = "dashboard_show_recent_meetings",
  dashboard_show_staff_on_leave = "dashboard_show_staff_on_leave",
  dashboard_show_staff_on_site = "dashboard_show_staff_on_site",
  dashboard_show_time_tracking_tile = "dashboard_show_time_tracking_tile",
  dashboard_show_todays_Roster = "dashboard_show_todays_Roster",
  dashboard_show_upcoming_birthdays = "dashboard_show_upcoming_birthdays",
  leave_requests_can_create = "leave_requests_can_create",
  leave_requests_can_view = "leave_requests_can_view",
  leave_requests_can_delete = "leave_requests_can_delete",
  leave_requests_can_edit = "leave_requests_can_edit",
  leave_requests_can_approve = "leave_requests_can_approve",
  performance_reviews_can_create = "performance_reviews_can_create",
  performance_reviews_can_view = "performance_reviews_can_view",
  performance_reviews_can_delete = "performance_reviews_can_delete",
  performance_reviews_can_edit = "performance_reviews_can_edit",
  performance_reviews_can_complete = "performance_reviews_can_complete",
  tasks_can_create = "tasks_can_create",
  tasks_can_view = "tasks_can_view",
  tasks_can_delete = "tasks_can_delete",
  tasks_can_edit = "tasks_can_edit",
  tasks_can_complete = "tasks_can_complete",
}
